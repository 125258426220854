import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DetailLayout from '../containers/layout/detail-layout'

const DisclaimerPage = ({ location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query DisclaimerPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "f3379ef9-dd35-5cf9-bd59-f07c70e4e7ff" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              description
            }
            content {
              json
            }
          }
        }
      }
    }
  `)

  const PageData = data.page.edges[0].node
  PageData.image = PageData.headerImage
  PageData.title = PageData.headerTitel
  PageData.subTitle = PageData.headerSubtitel
  pageContext.categories = []
  pageContext.metadata = data.site.siteMetadata

  return (
    <DetailLayout
      location={location}
      pageContext={pageContext}
      pageData={PageData}
    />
  )
}

export default DisclaimerPage
